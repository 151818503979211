<template>
  <div class="basic">
    <div>
      <div class="title">联系人信息</div>
      <div class="fl">
        <div class="w-box">
          <div class="subtitle">联系人姓名</div>
          <div class="c-box">{{contact_name}}</div>
        </div>
        <div class="w-box">
          <div class="subtitle">联系人电话</div>
          <div class="c-box">{{contact_phone}}</div>
        </div>
      </div>
    </div>
    <div>
      <div class="title">单位信息</div>
      <div class="fl">
        <div class="w-box">
          <div class="subtitle">单位名称</div>
          <div class="c-box">{{name}}</div>
        </div>
        <div class="w-box">
          <div class="subtitle">社会信用编码</div>
          <div class="c-box">{{social_credit_code}}</div>
        </div>
        <div class="w-box">
          <div class="subtitle">所属行业</div>
          <div class="c-box">{{industry_name}}</div>
        </div>
      </div>
      <div class="fl">
        <div class="w-box">
          <div class="subtitle">注册日期</div>
          <div class="c-box">{{founding_time}}</div>
        </div>
        <div class="w-box">
          <div class="subtitle">注册资本</div>
          <div class="c-box">{{registered_capital}}万元</div>
        </div>
        <div class="w-box">
          <div class="subtitle">法人代表</div>
          <div class="c-box">{{legalPerson}}</div>
        </div>
      </div>
      <div class="w-box">
        <div class="subtitle">行政区域</div>
        <div class="c-box">{{area}}</div>
      </div>
      <div class="m-box">
        <div class="subtitle">详细地址</div>
        <div class="c-box">{{address}}</div>
      </div>
      <div class="l-box">
        <div class="subtitle">经营范围</div>
        <div class="c-box">{{scope}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../../../../network';
// import { getMemberId } from '@/assets/public/utils/token'
export default {
  name: 'basic',
  data () {
    return {
      contact_name: '',
      contact_phone: '',
      name: '',
      social_credit_code: '',
      industry_name: '',
      founding_time: '',
      registered_capital: '',
      legalPerson: '',
      area: '',
      address: '',
      scope: ''
    };
  },
  mounted () {
    this.getFindInformation();
  },
  methods: {
    getFindInformation () {
      const that = this;
      request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findInformation',
        params: {
          memberId: localStorage.getItem('label_id')
        }
      })
        .then(res => {
          if (res.data.code === 200) {
            const str = res.data.data;
            that.contact_name = str.contact_name;
            that.contact_phone = str.contact_phone;
            that.name = str.name;
            that.social_credit_code = str.social_credit_code;
            that.industry_name = str.industry_name;
            that.founding_time = str.founding_time;
            that.registered_capital = str.registered_capital;
            that.legalPerson = str.legalPerson;
            str.administrativeDivision.forEach((v, i) => {
              if (i === str.administrativeDivision.length - 1) {
                that.area += v.name;
              } else {
                that.area += v.name + '-';
              }
            });
            that.address = str.address;
            that.scope = str.scope;
          }
        })
        .catch(err => { console.log(err); });
    }
  }
};
</script>

<style lang="less" scoped>
    .title{
        color: #156ED0;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .subtitle{
        color: #999;
        font-size: 14px;
    }
    .fl{
        display: flex;
    }
    .w-box{
        // width: 33%;
        margin-bottom: 40px;
        width: 250px;
        font-size: 14px;
        margin-right: 40px;
    }
    .m-box{
        width: 50%;
        margin-bottom: 30px;
    }
    .l-box{
        width: 100%;
    }
    .c-box{
        margin-top: 20px;
    }
    .ft{
      font-size: 14px;
    }
</style>
