<template>
  <div class="record">
    <div class="timeline">
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :type="activity.type"
        >
          <p class="l_text">{{activity.operation}}</p>
          <p class="m_text">日期：{{activity.operationTime}}</p>
          <p>内容：{{activity.operationMessage}}</p>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../../../network';
export default {
  name: 'record',
  data () {
    return {
      activities: []
    };
  },
  mounted () {
    this.getFollowMessage();
  },
  methods: {
    getFollowMessage () {
      request({
        method: 'GET',
        url: '/pcp/org/getFollowMessage',
        params: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId
        }
      }).then(res => {
        if (res.data.code === 200) {
          res.data.data.forEach(v => {
            v.type = 'primary';
          });
          this.activities = res.data.data;
        }
      })
        .catch(err => { console.log(err); });
    }
  }
};
</script>

<style lang="less" scoped>
.record {
  height: 100%;
  .timeline{
    height: 100%;
  }
}
.l_text{
  font-size: 18px;
  font-weight: bold;
}
.m_text{
  margin: 20px 0;
}
</style>
