<template>
  <div class="financial">
    <div class="top-title">
      <i class="el-icon-info">(以下所填信息，为上一年度数据)</i>
    </div>
    <div class="content">
      <div class="top">
        <div class="title">财务状况</div>
        <div
          v-for="(item, index) in finance"
          :key="index"
          style="margin-bottom: 20px"
          class="m-box"
        >
          <div class="s-box">{{ item.title }}</div>
          <div v-show="index<finance.length-1" class="s-box">{{ financeLabel[index] }}万元</div>
          <div v-show="index==finance.length-1" class="s-box">{{ financeLabel[index] }}%</div>
        </div>
      </div>
      <div class="middle">
        <div class="title">营收</div>
        <div
          v-for="(item, index) in revenue"
          :key="index"
          style="margin-bottom: 20px"
          class="m-box"
        >
          <div class="s-box">{{ item.title }}</div>
          <div class="s-box">{{ revenueLabel[index] }}万元</div>
        </div>
      </div>
      <div class="footer">
        <div class="title">研发</div>
        <div
          v-for="(item, index) in research"
          :key="index"
          style="margin-bottom: 20px"
          class="m-box"
        >
          <div class="s-box">{{ item.title }}</div>
          <div class="s-box">{{ researchLabel[index] }}万元</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../../../../network';
import { getEntityId } from '@/assets/public/utils/token';
export default {
  name: 'financial',
  data () {
    return {
      finance: [],
      revenue: [],
      research: [],
      financeLabel: [],
      revenueLabel: [],
      researchLabel: []
    };
  },
  mounted () {
    this.getFindFinancialSituation('');
    this.getFindFinancialSituation(getEntityId());
  },
  methods: {
    getFindFinancialSituation (entityId) {
      const that = this;
      request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findFinancialSituation',
        params: {
          entityId: entityId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            if (!entityId) {
              that.finance = res.data.data.financialSituation;
              that.revenue = res.data.data.revenue;
              that.research = res.data.data.research;
            } else {
              that.financeLabel = res.data.data.financialSituation;
              that.revenueLabel = res.data.data.revenue;
              that.researchLabel = res.data.data.research;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.financial {
  .top-title {
    background: #eee;
    padding: 10px 0;
    padding-left: 20px;
    border-radius: 5px;
    font-size: 16px;
  }
}
.title {
  color: #156ED0;
  font-size: 16px;
  font-weight: bold;
  margin: 30px 0;
}

.m-box{
   display: flex;
}
.s-box{
    width: 30%;
}

</style>
