<template>
  <div class="info">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本信息" name="basic"><basic></basic></el-tab-pane>
      <el-tab-pane label="经营活动" name="activity"><activity></activity></el-tab-pane>
      <el-tab-pane label="财务状况" name="financial"><financial></financial></el-tab-pane>
      <el-tab-pane label="人员状况" name="personnel"><personnel></personnel></el-tab-pane>
      <el-tab-pane label="知识产权" name="knowledge"><knowledge></knowledge></el-tab-pane>
      <el-tab-pane label="荣誉资质" name="honor"><honor></honor></el-tab-pane>
      <el-tab-pane label="贴标签" name="edit"><edit></edit></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import activity from './components/activity.vue';
import basic from './components/basic.vue';
import edit from './components/edit.vue';
import financial from './components/financial.vue';
import honor from './components/honor.vue';
import knowledge from './components/knowledge.vue';
import personnel from './components/personnel.vue';
export default {
  name: 'info',
  components: {
    activity,
    basic,
    edit,
    financial,
    honor,
    knowledge,
    personnel
  },
  data () {
    return {
      activeName: 'basic'
    };
  }
};
</script>

<style lang="less" scoped>
  .info{
    height: 100%;
  }
</style>
