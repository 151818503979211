<template>
    <div class="details">
        <el-row :gutter="20">
            <el-col :span="12">
                <el-card><info></info></el-card>
            </el-col>
            <el-col :span="12">
                <el-card><record></record></el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import info from './components/Info.vue';
import record from './components/Record.vue';
export default {
  components: {
    info,
    record
  },
  data () {
    return {

    };
  }
};
</script>

<style lang="less" scoped>
    .details{
        margin: 20px;
        height: 100%;
    }
</style>
